import React from "react"
import PropTypes from "prop-types"

const Emoji = ({ label, emoji }) => (
  <span role="img" aria-label={label}>{emoji}</span>
)

Emoji.defaultProps = {
  label: ``,
  emoji: ``,
}

Emoji.propTypes = {
  label: PropTypes.string,
  emoji: PropTypes.string,
}

export default Emoji