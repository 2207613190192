import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'

import { categoryColor } from '../utils/categoryColor'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'

const Article = ({ node, isRelated}) => {
  const data = useStaticQuery(graphql`
    query ArticleQuery {
      noimage: file(absolutePath: { regex: "/noimage.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const title = node.frontmatter.title || node.fields.slug
  const classes = useStyles()

  const truncate = (str, len) => {
    if (str == null)
      return ""

    return str.length <= len ? str: (str.substr(0, len)+"...");
  }

  return (
    <div id="articles">
      <Tooltip title={title} arrow>
        <Link to={node.fields.slug}>
          <article className={classes.article}>
            <Card>
              <CardMedia
                className={classes.media}
                image={node.frontmatter.cover == null ? data.noimage.childImageSharp.fluid.src : node.frontmatter.cover.childImageSharp.fluid.src}
                title={title}
              />
              <CardContent>
                <Typography variant={isRelated ? "body1" : "h6"} component="div">
                  {isRelated ? truncate(title, 28) : title}
                </Typography>
                <Typography variant="body2" className={classes.date} component="p">
                  {node.frontmatter.date}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p"
                  dangerouslySetInnerHTML={{
                    __html: truncate(node.frontmatter.description, 50),
                  }}
                />
              </CardContent>
              <Typography variant="body2" className={classes.categoryArea} component="p">
                <span className={classes.category} style={{background: categoryColor(node.frontmatter.category[0])}}>
                  {node.frontmatter.category[0].toUpperCase()}
                </span>
              </Typography>
            </Card>
          </article>
        </Link>
      </Tooltip>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
  },
  article: {
    boxShadow: "none",
    '&:hover': {
      border: `3px solid black`,
      boxSizing: `border-box`,
    }
  },
  date: {
    color: `#00b0ff`,
  },
  categoryArea: {
    padding: `4px`,
    marginBottom: theme.spacing(0),
    color: `white`,
    width: `auto`,
    textAlign: `center`,
  },
  category: {
    padding: theme.spacing(1),
    fontSize: `12px`,
  }
}))

Article.defaultProps = {
  node: {},
  num: 4,
}

Article.propTypes = {
  node: PropTypes.object,
  num: PropTypes.number
}

export default Article