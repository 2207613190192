import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden';

const BottomBanner = ({ category = "development" }) => {

  const classes = useStyles()
  return (
    <div className={classes.root}>
      {(category === "food") ? (
        ""
      ) : (
        <React.Fragment>
          {/* desctop */}
          <Hidden mdDown>
            <a href="https://px.a8.net/svt/ejp?a8mat=3H5UZF+1KK78Y+4LXM+61JSH" rel="noopener noreferrer sponsored" target="_blank">
              <img border="0" width="728" height="90" alt="" src="https://www23.a8.net/svt/bgt?aid=210225435095&wid=001&eno=01&mid=s00000021505001015000&mc=1" />
            </a>
            <img border="0" width="1" height="1" src="https://www13.a8.net/0.gif?a8mat=3H5UZF+1KK78Y+4LXM+61JSH" alt="" />
          </Hidden>
          {/* mobile */}
          <Hidden lgUp>
            <a href="https://px.a8.net/svt/ejp?a8mat=3H5UZF+1KK78Y+4LXM+60OXD" rel="noopener noreferrer sponsored" target="_blank">
              <img border="0" width="336" height="280" alt="" src="https://www25.a8.net/svt/bgt?aid=210225435095&wid=001&eno=01&mid=s00000021505001011000&mc=1" />
            </a>
            <img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3H5UZF+1KK78Y+4LXM+60OXD" alt=""/>
          </Hidden>
        </React.Fragment>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: `center`,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

export default BottomBanner