import React from "react"
import { graphql, Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'
import { createGlobalStyle } from "styled-components";

import Img from "gatsby-image"
import AdsCard from "../components/adsCard"
import Work from "../components/work"
import BottomBanner from "./../components/bottomBanner"
import { categoryColor } from '../utils/categoryColor'

import ShareButton from "../components/buttons/shareButton"
import RelatedPost from "../components/relatedPost"

import AnchorLink from 'react-anchor-link-smooth-scroll'

import Layout from "../components/layout"
import SEO from "../components/seo"
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined'
import PhotoSizeSelectLargeOutlinedIcon from '@material-ui/icons/PhotoSizeSelectLargeOutlined';
import NoteIcon from '@material-ui/icons/Note'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const { author, social } = data.site.siteMetadata

  const post = data.markdownRemark
  const titleColor = post.frontmatter.color || 'white'
  const classes = useStyles()

  // dialog
  const [open, setOpen] = React.useState(false)
  const withFullDaialog = true
  const handleClickOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Layout location={location} title={post.frontmatter.title} headerSize="small">
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.cover === null ? data.noimage.childImageSharp.fluid.src : post.frontmatter.cover.childImageSharp.fluid.src}
        slug={post.fields.slug}
      />
      <div style={{
        backgroundImage: "url(" + post.frontmatter.cover.childImageSharp.fluid.src + ")",
      }} className={classes.parallax}>
        <div className={classes.titleCover}>
          <h1 className={classes.title} style={{color: titleColor}}>
            {post.frontmatter.title}
            <Typography variant="h6" component="div" className={classes.date}>{post.frontmatter.date}</Typography>
          </h1>
          <div className={classes.arrow}>
            <AnchorLink href="#article" offset="0" className={classes.link}>
              <IconButton className={classes.iconButton}>
                <ExpandMoreOutlinedIcon className={classes.icon} />
              </IconButton>
            </AnchorLink>
          </div>
        </div>
        <div className={classes.largeImage}>
          <IconButton className={classes.iconButton2} onClick={handleClickOpen}>
            <PhotoSizeSelectLargeOutlinedIcon className={classes.icon} style={{color: titleColor}}/>
          </IconButton>
        </div>
      </div>

      <Dialog
        fullWidth={withFullDaialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Img
            fluid={post.frontmatter.cover === null ? data.noimage.childImageSharp.fluid : post.frontmatter.cover.childImageSharp.fluid}
            className={classes.coverImg}
            objectFit="fill"
            objectPosition="50%"
            alt=""
          />
        </DialogContent>
      </Dialog>

      <Container maxWidth="md">
        <GlobalStyle />
        <article id="article" className={classes.article}>
          <Typography variant="body2" className={classes.categoryArea} component="p">
            <span className={classes.category} style={{background: categoryColor(post.frontmatter.category[0])}}>
              {post.frontmatter.category[0].toUpperCase()}
            </span>
          </Typography>
          <div className={classes.chipArea}>
            {post.frontmatter.tags.map((tag) => {
              return (
                <Chip
                  key={tag}
                  size="small"
                  icon={<LocalOfferIcon />}
                  label={tag}
                  color="primary"
                  className={classes.chip}
                />
              )
            })}
          </div>


          <div className={classes.markdown + " body"}>
            <h1>目次</h1>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item md={4} sm={12}>
                <div dangerouslySetInnerHTML={{ __html: post.tableOfContents }} />
              </Grid>

              <Grid item md={4} sm={12} className={classes.ads}>
                { /*<div>
                  <a href="https://px.a8.net/svt/ejp?a8mat=3H5UZF+1IRWFM+4DKI+609HT" rel="noopener noreferrer sponsored" target="_blank">
                    <img border="0" width="300" height="250" alt="" src="https://www21.a8.net/svt/bgt?aid=210225435092&wid=001&eno=01&mid=s00000020421001009000&mc=1" />
                  </a>
                  <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3H5UZF+1IRWFM+4DKI+609HT" alt="" />
                </div>*/}
              </Grid>
              <Grid item md={4} sm={12} className={classes.ads} >
                <div>
                  { (post.frontmatter.category[0] === "food") ? (
                    ""
                  ) : (
                    <React.Fragment>
                      <a href="https://px.a8.net/svt/ejp?a8mat=3H5UZF+1L5MUQ+3TVC+BXYE9" rel="noopener noreferrer sponsored" target="_blank">
                        <img border="0" width="300" height="250" alt="" src="https://www26.a8.net/svt/bgt?aid=210225435096&wid=001&eno=01&mid=s00000017868002006000&mc=1" />
                      </a>
                      <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3H5UZF+1L5MUQ+3TVC+BXYE9" alt="" />
                    </React.Fragment>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>

          <AdsCard slot="5723982973" />
          <br />
          <Typography variant="body1" component="div" className={classes.markdown + " body"}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />

          {/*
          <div className={classes.markdown + " body"}>
            <h2>ITフリーランス向け 案件探しなら</h2>
            <ul>
              <li>
                <a target="_blank" href="//af.moshimo.com/af/c/click?a_id=2476272&p_id=2629&pc_id=5881&pl_id=33479&guid=ON" rel="noopener noreferrer sponsored" referrerPolicy="no-referrer-when-downgrade">
                  フューチャリズム｜フリーエンジニア専門の案件紹介サービスの申込
                </a>
                <img src="//i.moshimo.com/af/i/impression?a_id=2476272&p_id=2629&pc_id=5881&pl_id=33479" width="1" height="1" styles="border:none;" alt="" />
              </li>
              <li>
                <a target="_blank" href="https://px.a8.net/svt/ejp?a8mat=3H5UZF+1L5MUQ+3TVC+BZ8OY" rel="noopener noreferrer sponsored">
                  フリーランスエンジニアの豊富な案件なら【midworks】
                </a>
                <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3H5UZF+1L5MUQ+3TVC+BZ8OY" alt="" />
              </li>
              <li>
                <a target="_blank" href="https://px.a8.net/svt/ejp?a8mat=3H5UZF+1JDC1E+4754+61JSI" rel="noopener noreferrer sponsored">
                  はじめてのフリーランスなら、担当者が一気通貫でサポート！【エミリーエンジニア】
                </a>
                <img border="0" width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3H5UZF+1JDC1E+4754+61JSI" alt="" />
              </li>
              <li>
                <a target="_blank" href="https://px.a8.net/svt/ejp?a8mat=3H5UZF+1KK78Y+4LXM+62MDE" rel="noopener noreferrer sponsored">
                  案件数業界最大級のIT求人ナビフリーランス
                </a>
                <img border="0" width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3H5UZF+1KK78Y+4LXM+62MDE" alt="" />
              </li>
              <li>
                <a target="_blank" href="https://px.a8.net/svt/ejp?a8mat=3H5UZF+1IRWFM+4DKI+60H7M" rel="noopener noreferrer sponsored">PRO WORKS-プロワークス</a>
                <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3H5UZF+1IRWFM+4DKI+60H7M" alt="" />
              </li>
            </ul>
          </div>
          */}

          <ShareButton url={post.fields.slug} title={post.frontmatter.title} media={post.frontmatter.cover === null ? data.noimage.childImageSharp.fluid.src : post.frontmatter.cover.childImageSharp.fluid.src} />

          <div className={classes.author}>
            <Card className={classes.card}>
              <CardHeader
                avatar={
                  <Avatar className={classes.avatar} src={data.avatar.childImageSharp.fixed.src} alt={`${author.name} / ${author.summary}`} />
                }
                title={`${author.name}@${author.degree}`}
                subheader={<span>{author.summary}</span>}
                className={classes.cardHeader}
                action={
                  <React.Fragment>
                    <a className={classes.link} href={`https://twitter.com/${social.twitter}`} rel="noopener noreferrer" target="_blank">
                      <Tooltip title="Go Twitter"><IconButton className={classes.twitter}><TwitterIcon className={classes.link}/></IconButton></Tooltip>
                    </a>
                    <a className={classes.link} href={`https://www.instagram.com/${social.instagram}`} rel="noopener noreferrer" target="_blank">
                      <Tooltip title="Go Instagram"><IconButton className={classes.instagram}><InstagramIcon className={classes.link}/></IconButton></Tooltip>
                    </a>
                    <a className={classes.link} href={`https://note.com/${social.note}`} rel="noopener noreferrer" target="_blank">
                      <Tooltip title="Go Note"><IconButton className={classes.note}><NoteIcon className={classes.link}/></IconButton></Tooltip>
                    </a>
                  </React.Fragment>
                }
              />
              <CardContent>
                <Typography variant="body1" component="span">
                  Nobuyuki Ukai
                </Typography>
                <Typography variant="body2" component="p">
                  {author.summary} 代表取締役CEO。建築を専攻していたが、新卒でYahoo!Japanにエンジニアとして入社。その後、転職、脱サラ、フリーランスエンジニアを経て、田舎へ移住し、ゲストハウスの開業、法人成り。ゲストハウス２軒、焼肉店、カフェ、食品製造業を運営しています。詳細は<Link style={{color: `black`}} to="/about/">こちら</Link>から
                </Typography>
              </CardContent>
            </Card>
          </div>
          {/* ADS:  */}
          <BottomBanner category={post.frontmatter.category[0]}/>
          {/* <AdsCard slot="9009456012" /> */}
          <Work />
          <br />
          <RelatedPost post={post} />
          {/* <AdsCard slot="5723982973" /> */}
          <AdsCard slot="9009456012" />
        </article>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author {
          name
          summary
          degree
        }
        social {
          twitter
          note
          instagram
        }
        siteUrl
      }
    }
    avatar: file(absolutePath: { regex: "/avatar.png/" }) {
      childImageSharp {
        fixed(width: 130, height: 130) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    noimage: file(absolutePath: { regex: "/noimage.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        color
        category
        tags
        cover {
          childImageSharp {
            fluid(maxHeight: 560, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      tableOfContents
    }
  }
`
const GlobalStyle = createGlobalStyle`
  #article {
    a {
      box-shadow: none;
      color: #007acc;
      text-decoration: none;
    }
    .body {
      h1 {
        padding: 1.5rem 0;
      }
      h2 {
        padding: 1.3rem 0;
      }
      h3 {
        padding: 1.0rem 0;
      }
      h4 {
        padding: 0.8rem 0;
      }
      h5 {
        padding: 0.7rem 0;
      }
      h1,h2,h3,h4,h5,h6 {
        font-family: Montserrat,sans-serif;
        margin: 2rem 0 1.8rem 0;
        border-left: 0.42813rem solid #00b0ff;
        padding-left: 1.42188rem;
        color: #00b0ff;
        background-color: #f7f7f7;
        font-weight: bold;
      }

      pre {
        border: 5px solid #555;
        background: #efefef;
        padding: 10px;
        margin: 1.25rem 0;
      }
      code[class*="language-"], pre[class*='language-'] > code {
        white-space: pre-wrap;
        color: black;
        font-size: 16px;
        text-shadow: none;
      }
      mark {
        background: #00b0ff
      }
      blockquote {
        margin: 10px 0rem;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #efefef;
        p {
          margin-bottom: 0;
        }
      }
      ul, ol {
        margin-left: 1.25rem;
        li {
          p {
            margin-bottom: 0;
          }
          blockquote {
            margin: 10px .25rem;
          }
        }
      }
      p {
        line-height: 2;
        letter-spacing: 3px;
        margin-bottom: 5rem;
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    flexGrow: 1,
  },
  titleCover: {
    position: `absolute`,
    [theme.breakpoints.up('sm')]: {
      left: `25%`,
    },
    left: `5%`,
    maxWidth: `984px`,
    width: `95%`,
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      width: `75%`,
    },
    width: `100%`,
    wordBreak: `break-word`,
    color: `white`,
    marginTop: theme.spacing(10),
  },
  date: {
    display: `block`,
  },
  coverImg: {
    maxHeight: `430px`,
    objectFit: `cover`
  },
  parallax: {
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `center`,
    height: `450px`,
    marginBottom: `15px`,
    borderImage: 'black',
    borderImageSlice: '1',
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
  },
  // 未使用
  cover: {
    position: `relative`,
    marginBottom: theme.spacing(3),
    borderBottom: '10px solid',
    borderImage: 'black',
    borderImageSlice: '1',
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
  },
  arrow: {
    textAlign: `center`,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      width: `75%`,
    },
    width: `95%`,
  },
  iconButton: {
    background: `#00b0ff`,
  },
  iconButton2: {
    [theme.breakpoints.up('sm')]: {
      display: "block"
    },
    display: "none"
  },
  goText: {
    color: `white`,
  },
  icon: {
    fontSize: 40,
    color: `white`,
  },
  largeImage: {
    position: `absolute`,
    right: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      right: theme.spacing(5),
    },
  },
  article: {
    marginBottom: theme.spacing(5),
  },
  markdown: {
    fontFamily: `Montserrat,sans-serif`,
    fontWeight: 800,
    lineHeight: 2.0,
    color: `#454545`,
    fontSize: `1.2rem`,
  },
  link: {
    color: `white`,
    textDecoration: `none`,
    boxShadow: `none`,
  },
  hr: {
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
    borderTop: `5px solid black`,
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  chipArea: {
    textAlign: `right`,
    paddingBottom: theme.spacing(3),
  },
  chip: {
    color: `white`,
  },
  categoryArea: {
    padding: `4px`,
    marginBottom: theme.spacing(3),
    color: `white`,
    width: `auto`,
    textAlign: `right`,
  },
  category: {
    padding: theme.spacing(1),
    fontSize: `12px`,
  },
  content: {
    color: `#00b0ff`,
    textAlign: `left`,
  },
  twitter: {
    background: `#00b0ff`,
  },
  instagram: {
    background: `linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)`,
    filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 )`,
  },
  note: {
    background: `#2cb696`,
  },
  author: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  ads: {
    margin: `0 auto`,
  }
}))

export default BlogPostTemplate
