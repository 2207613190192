import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'
import { useStaticQuery, graphql } from "gatsby"

import Emoji from '../emoji'

import Typography from '@material-ui/core/Typography'

import {
  FacebookShareButton,
  LineShareButton,
  PinterestShareButton,
  PocketShareButton,
  TwitterShareButton,
  FacebookIcon,
  LineIcon,
  PinterestIcon,
  PocketIcon,
  TwitterIcon
} from "react-share"

const ShareButton = ({ title, url, media }) => {

  const data = useStaticQuery(graphql`
    query ShareButtonQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const classes = useStyles()
  const siteUrl = `${data.site.siteMetadata.siteUrl}${url}`

  return (
    <div className={classes.root} >
      <Typography variant="body1" component="div" className={classes.text}>⬇<Emoji label="onegai" emoji="🙏" />よかったらシェアお願いします<Emoji label="onegai" emoji="🙏" />⬇</Typography>
      <TwitterShareButton url={siteUrl} title={title}>
        <TwitterIcon />
      </TwitterShareButton>
      <FacebookShareButton url={siteUrl} title={title}>
        <FacebookIcon />
      </FacebookShareButton>
      <LineShareButton url={siteUrl} title={title}>
        <LineIcon />
      </LineShareButton>
      <PinterestShareButton url={siteUrl} title={title} media={media}>
        <PinterestIcon />
      </PinterestShareButton>
      <PocketShareButton url={siteUrl} title={title}>
        <PocketIcon />
      </PocketShareButton>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    textAlign: `center`,
    [theme.breakpoints.up('sm')]: {
      textAlign: `left`,
    },
  },
  text: {
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      textAlign: `left`,
    },
  }
}))

ShareButton.defaultProps = {
  title: `勢いで起業した代表取締役のテキトーブログ | STAY FREE`,
  url: `https://blog.u-chan-chi.com`,
  media: `https://blog.u-chan-chi.com/avatar.png`
}

ShareButton.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  media: PropTypes.string,
}

export default ShareButton