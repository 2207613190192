import React from "react"
import { navigate, graphql, useStaticQuery } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'

import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button';

const Work = () => {
  const data = useStaticQuery(graphql`
    query WorkQuery {
      workImage: file(absolutePath: { regex: "/work.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const image = data.workImage.childImageSharp.fluid.src
  const classes = useStyles()

  const title = "ホームページ作成、ECサイト掲載、商品ブランディング等、ご依頼やご相談は"
  return (
    <Tooltip title="詳細へ" arrow>
      <div style={{
        backgroundImage: "url(" + image + ")",
      }} className={classes.parallax}>
        <div className={classes.titleCover}>
          <h1 className={classes.title} style={{color: `white`}}>
            {title}
          </h1>
          <Button onClick={() => navigate("/work/")} variant="contained" size="large" color="primary" className={classes.button}>
            こちら >
          </Button>
        </div>
      </div>
    </Tooltip>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    flexGrow: 1,
  },
  media: {
    height: 300,
  },
  article: {
    boxShadow: "none",
    '&:hover': {
      border: `3px solid black`,
      boxSizing: `border-box`,
    }
  },
  titleCover: {
    position: `absolute`,
    [theme.breakpoints.up('sm')]: {
      left: `25%`,
    },
    left: `5%`,
    maxWidth: `984px`,
    width: `95%`,
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      width: `75%`,
    },
    width: `100%`,
    wordBreak: `break-word`,
    color: `white`,
    marginTop: theme.spacing(12),
  },
  link: {
    color: `white`,
    textDecoration: `none`,
    boxShadow: `none`,
  },
  parallax: {
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `center`,
    height: `450px`,
    marginBottom: `15px`,
    borderImage: 'black',
    borderImageSlice: '1',
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
  },
  // 未使用
  cover: {
    position: `relative`,
    marginBottom: theme.spacing(3),
    borderBottom: '10px solid',
    borderImage: 'black',
    borderImageSlice: '1',
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
  },
  button: {
    color: "white"
  }
}))

export default Work