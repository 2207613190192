import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { makeStyles } from '@material-ui/core/styles'

import Article from "./article"
import AdsCard from "../components/adsCard"

import Grid from '@material-ui/core/Grid'

const relatedPostCount = 9

const RelatedPost = ({ post }) => {
  const classes = useStyles()

  return(
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }){
            edges {
              node {
                fields {
                  slug
                }
                frontmatter {
                  title
                  cover {
                    childImageSharp {
                      fluid(maxWidth: 1000, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                  date(formatString: "MMMM DD, YYYY")
                  category
                  tags
                }
              }
            }
          }
        }
      `}
      render={data => {
        const relatedPosts = data.allMarkdownRemark.edges.filter( edge => {
          if(edge.node.frontmatter.title === post.frontmatter.title){
            return false
          }
          else if ( edge.node.frontmatter.title.match(`/${post.frontmatter.title}/`)) {
            return true
          }
          if(edge.node.frontmatter.category.length > 0 && edge.node.frontmatter.category[0] !== post.frontmatter.category[0]){
            return false
          }
          for (let i = 0; i < edge.node.frontmatter.tags.length; i++) {
            if (edge.node.frontmatter.tags[i] !== post.frontmatter.tags[i]){
              return false
            }
            else{
              return true
            }
          }
          return false
        })
        if (relatedPosts.length > 0 ) {
          const selected = [...Array(relatedPostCount)].map(() => relatedPosts.splice(Math.floor(Math.random() * relatedPosts.length), 1)[0])
          return (
            <div id="related" style={{paddingBottom: `40px`}}>
              <h2>関連記事</h2>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                {selected.map((edge, index) => {
                  if (edge !== undefined) {
                    const node = edge.node
                    const element = <Grid item md={4} sm={12} key={node.fields.slug} className={classes.root}>
                                <Article node={node} />
                              </Grid>
                    if (index === 5) {
                      return (
                        <React.Fragment key={`${node.fields.slug}-${index}ads-f`}>
                          {element}
                          <Grid item md={12} sm={12} className={classes.root}>
                            <AdsCard slot="9009456012" />
                          </Grid>
                        </React.Fragment>
                      )
                    }
                    else {
                      return element
                    }
                  }
                  else {
                    return ""
                  }
                })}
              </Grid>
            </div>
          )
        }
        else {
          return(<div></div>)
        }

      }}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    flexGrow: 1,
  },
}))

RelatedPost.defaultProps = {
  post: {},
}

RelatedPost.propTypes = {
  post: PropTypes.object,
}

export default RelatedPost