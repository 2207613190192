export const categoryColor = (category) => {
  switch(category) {
    case 'development':
      return `#8bc34a`
    case 'diary':
      return `#ff9800`
    case 'howto':
      return `#7e57c2`
    case 'food':
      return `#ff5252`
    default:
      return `black`
  }
}